// src/pages/HomePage.js
import React from "react";

function HomePage() {
  return (
    <div>
      <h1>My HomePage</h1>
    </div>
  );
}

export default HomePage;
